<template>
  <div>
    <v-skeleton-loader v-if="!data_loaded" type="table"></v-skeleton-loader>
    <v-card v-if="data_loaded">
      <v-card-title>
        <v-icon x-large>manage_accounts</v-icon>
        <h1 class="ms-3">עובדים</h1>
        <v-spacer></v-spacer>
        <v-btn color="primary" depressed @click="excel_model = true"
          >העלאת קובץ</v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          class="text-center"
          :headers="headers"
          :items="items"
          mobile-breakpoint="0"
        >
          <template v-slot:item="{ item }">
            <tr @click="edit_row(item)">
              <td>{{ item.name }}</td>
              <td>{{ item.phone_number }}</td>
              <td>{{ item.id_number || "_" }}</td>
              <td>{{ item.country || "_" }}</td>
              <td>
                {{ item.group ? item.group : "__" }}
              </td>
              <td>
                <v-chip
                  x-small
                  class="px-2"
                  :color="item.status ? 'success black--text' : 'error'"
                >
                  {{ item.status ? "פעיל" : "לא פעיל" }}
                </v-chip>
              </td>
              <td>{{ item.created_at }}</td>
            </tr>

            <tr v-show="item.form" class="grey lighten-3">
              <td colspan="10">
                <v-form :ref="`form${item.id}`" :class="`dd_${item.id}`">
                  <v-row class="basic mt-2 pb-4">
                    <v-col class="py-0">
                      <v-text-field
                        background-color="white"
                        outlined
                        dense
                        :rules="[(v) => !!v || 'חובה']"
                        v-model="item.name"
                      >
                        <template slot="label">
                          <strong class="red--text">*</strong>
                          שם
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        background-color="white"
                        outlined
                        dense
                        @keydown="input_change(item, 'phone_number_exist')"
                        v-model="item.phone_number"
                        type="number"
                        :rules="[
                          (v) => !!v || 'חובה',
                          !item.phone_number_exist || 'כבר קיים',
                        ]"
                      >
                        <template slot="label">
                          <strong class="red--text">*</strong>
                          מספר טלפון
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        background-color="white"
                        outlined
                        dense
                        @keydown="input_change(item, 'id_number_exist')"
                        v-model="item.id_number"
                        type="number"
                        label="ת.ז"
                        :rules="[!item.id_number_exist || 'כבר קיים']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        background-color="white"
                        outlined
                        dense
                        v-model="item.country"
                        label="עיר"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0">
                      <v-select
                        background-color="white"
                        outlined
                        dense
                        item-text="name"
                        item-value="id"
                        :items="groups"
                        v-model="item.group_id"
                        label="קבוצה"
                      ></v-select>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="item.password"
                        background-color="white"
                        outlined
                        dense
                      >
                        <template slot="label"> סיסמה </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        background-color="white"
                        readonly
                        outlined
                        dense
                        label="סטטווס"
                        :value="item.status ? 'פעיל' : 'לא פעיל'"
                      >
                        <template slot="append">
                          <v-switch
                            v-model="item.status"
                            class="mt-0"
                            hide-details
                          ></v-switch>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col class="py-0 d-flex justify-space-between mb-5">
                      <v-btn
                        depressed
                        height="40"
                        width="59%"
                        :disabled="item.save_loader"
                        :loading="item.save_loader"
                        color="black white--text"
                        @click="save_update(item)"
                        >שמירה</v-btn
                      >
                      <v-btn
                        depressed
                        height="40"
                        width="33%"
                        class="mr-2"
                        color="white "
                        @click="delete_row(item.id)"
                      >
                        <v-icon color="black">delete_sweep </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-snackbar color="#333" left v-model="snackbar_success">
      <p class="white--text font-weight-bold">עודכן בהצלחה</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <v-dialog persistent v-model="excel_model" max-width="500">
      <v-card>
        <v-card-title>העלאת קובץ</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-alert
            v-if="upload_excel"
            outlined
            dense
            color="info"
            class="mb-5 py-3"
          >
            <div class="d-flex justify-space-around">
              <p>
                <strong> עובדים חדשים : </strong>
                {{ total_excel - total_exists }}
              </p>
              <p><strong> כבר קיימים : </strong> {{ total_exists }}</p>
              <p><strong> סה”כ : </strong> {{ total_excel }}</p>
            </div>
          </v-alert>
          <v-form ref="excel_form">
            <v-file-input
              prepend-icon="attach_file"
              outlined
              dense
              label="excel"
              v-model="excel"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              :rules="[(v) => !!v || 'חובה']"
            ></v-file-input>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            depressed
            color="primary"
            :loading="excel_loader"
            :disabled="excel_loader"
            @click="upload_excel()"
            >הורדה</v-btn
          >
          <v-btn
            depressed
            color="error"
            :disabled="excel_loader"
            text
            @click="close_excel()"
            >ביטול</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DeleteDialog
      @delete_done="get_data()"
      @close_delete="delete_obj.delete_prop = false"
      :delete_obj="delete_obj"
    ></DeleteDialog>
  </div>
</template>

<script>
import DeleteDialog from "@/components/public/delete";
export default {
  name: "Table",
  props: ["data_loaded", "places", "groups"],
  data() {
    return {
      excel_uploaded: false,
      excel_loader: false,
      excel: null,
      excel_model: false,
      snackbar_success: false,
      total_excel: 0,
      total_exists: 0,
      delete_obj: {
        delete_prop: false,
        delete_url: "employees",
        title: "מחק עובד",
        id: null,
      },
      headers: [
        {
          value: "name",
          text: "שם",
          align: "center",
        },
        {
          value: "phone_number",
          text: "מספר טלפון",
          align: "center",
        },
        {
          value: "id_number",
          text: "ת.ז",
          align: "center",
        },
        {
          value: "country",
          text: "עיר",
          align: "center",
        },
        {
          value: "group",
          text: "קבוצה",
          align: "center",
        },
        {
          value: "status",
          text: "סטטווס",
          align: "center",
        },

        {
          value: "created_at",
          text: "תאריך",
          align: "center",
        },
      ],
      items: [],
    };
  },
  components: {
    DeleteDialog,
  },
  computed: {
    passowrd_length: (app) => (item) => {
      return item.password.length > 7;
    },
    not_matching: (app) => (item) => {
      return item.password == item.password_confirmation;
    },
  },
  methods: {
    close_excel() {
      this.excel = null;
      this.$refs.excel_form.resetValidation();
      this.total_excel = 0;
      this.total_exists = 0;
      this.excel_model = false;
      this.excel_loader = false;
      this.upload_excel = false;
    },
    upload_excel() {
      if (this.$refs.excel_form.validate() && !this.excel_loader) {
        const formData = new FormData();
        formData.append("file", this.excel);
        this.$store
          .dispatch("public__request", {
            config: {
              url: "import_employees",
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            this.total_excel = res.data.data.total;
            this.total_exists = res.data.data.exists;
            this.excel_uploaded = true;
            this.excel = null;
            this.$refs.excel_form.resetValidation();
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
    input_change(item, type) {
      this.$set(item, `${type}`, false);
    },
    edit_row(item) {
      this.$emit("close_form");
      this.items.forEach((e) => {
        e.form = false;
      });
      item.form = true;
    },
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "/employees",
            method: "get",
          },
        })
        .then((res) => {
          this.$emit("data_loaded");
          this.items = Object.assign([], res.data.data);
          
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    save_update(item) {
      if (this.$refs[`form${item.id}`].validate() && !item.save_loader) {
        item.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `employees/${item.id}`,
              method: "put",
            },
            data: item,
          })
          .then((res) => {
            item.save_loader = false;
            this.$emit("update_data");

            this.snackbar_success = true;
          })
          .catch((err) => {
            item.save_loader = false;
            let errors = err.response.data.data;
            Object.keys(errors).forEach((e) => {
              errors[e].forEach((s) => {
                if (s.includes("already been taken")) {
                  this.$set(item, `${e}_exist`, true);
                }
              });
            });
          });
      }
    },
    delete_row(id) {
      this.delete_obj.id = id;
      this.delete_obj.delete_prop = true;
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style lang="scss" scoped>
.basic {
  .col {
    flex: 0 0 20%;
    max-width: 20%;
    @media (max-width: 991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
</style>
